import './App.css';
import ButtonAppBar from './components/Encabezado';

function App() {
  
  
  return (
    <div className="App">
        <ButtonAppBar/>
    </div>
  );
}

export default App;
