import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Button, Dialog, DialogTitle, DialogContent, Box, CircularProgress } from '@mui/material';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
const columns = [
  { field: 'Pg_Id', headerName: 'ID', width: 180 },
  {
    field: 'Prestamo.Pr_Numero',
    headerName: 'Numero',
    width: 180,
    renderCell: (params) => params?.row?.Prestamo?.Pr_Numero || '',
  },
  {
    field: 'Prestamo.Cliente.Cl_Nombre',
    headerName: 'Nombre cliente',
    width: 180,
    renderCell: (params) => params?.row?.Prestamo?.Cliente?.Cl_Nombre || '',
  },
  {
    field: 'Pg_FechaPago',
    headerName: 'Fecha de pago',
    width: 220,
    renderCell: (params) => moment(params.row.Pg_FechaPago).format('L'),
  },
  {
    field: 'Pg_FechaRegistro',
    headerName: 'Fecha de registro',
    width: 280,
    renderCell: (params) => moment(params.row.Pg_FechaRegistro).format('L'),
  },
  //   { field: 'Pg_Cuota', headerName: 'Cuota', width: 180 },
  { field: 'Pg_Monto', headerName: 'Monto', width: 180 },
  {
    field: 'Pg_Adjunto',
    headerName: 'Adjunto',
    width: 180,
    renderCell: (params) => <VisualizeButton base64File={params.value} />,
  },
];

function VisualizeButton({ base64File }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getFileType = (base64) => {
    if (base64.startsWith('JVBERi0')) {
      return 'pdf';
    } else if (base64.startsWith('/9j/') || base64.startsWith('iVBORw0KGgo=') || base64.startsWith('R0lGODlh')) {
      return 'image';
    } else {
      return 'unknown';
    }
  };

  const fileType = getFileType(base64File);

  return (
    <>
      <Button variant='contained' color='primary' onClick={handleOpen}>
        Visualizar
      </Button>

      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogTitle>
          Comprobante
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
            {fileType === 'image' && <img src={`data:image/jpeg;base64,${base64File}`} alt='Adjunto' style={{ maxWidth: '100%', maxHeight: '100%' }} />}
            {fileType === 'pdf' && (
              <iframe src={`data:application/pdf;base64,${base64File}`} title='PDF' width='100%' height='100%' style={{ border: 'none' }}>
                <p>
                  Tu navegador no soporta visualizar PDFs.{' '}
                  <a href={`data:application/pdf;base64,${base64File}`} download='file.pdf'>
                    Descargar el PDF
                  </a>
                  .
                </p>
              </iframe>
            )}
            {fileType === 'unknown' && <p>Formato de archivo no soportado.</p>}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default function DataTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios.get('https://verdermosowebapichatbot.azurewebsites.net/api/Pagos/getPagos').then((response) => {
      setData(response.data);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        getRowId={(row) => row.Pg_Id}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        sx={{
          '& .MuiDataGrid-cell': {
            fontSize: 19,
          },
          '& .MuiDataGrid-columnHeaders': {
            fontSize: 19,
          },
        }}
      />
    </Box>
  );
}
